import {Store} from "redux";
import {configureStore} from "@reduxjs/toolkit";
import {createBrowserHistory, History} from "history";
import {rootReducer} from "../store";

const history:History=createBrowserHistory();
const store:Store=configureStore({
    reducer: rootReducer(history),
    middleware: (gdm:any) => gdm({serializableCheck: false}),
});

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
