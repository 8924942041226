import {combineReducers, Action, Reducer} from "redux";
import {connectRouter, RouterState} from "connected-react-router";
import {History} from "history";
import {ConfActionTypes, ConfState} from "./types/confType";
import {confReducer} from "./reducers/confReducer";

interface AppState {
    conf:ConfState
    router:RouterState
}

/**
 * appReducer
 * @param {History} history
 * @return {Reducer<AppState>}
 */
const appReducer=(history:History):Reducer<AppState> => combineReducers({
    conf: confReducer,
    router: connectRouter(history),
});

/**
 * rootReducer
 * @param {History} history
 * @return {Reducer<AppState>}
 */
export const rootReducer=(history:History):Reducer<AppState> => (state:any, action:Action) => {
    switch (action.type) {
        case ConfActionTypes.CONF_SIGNOUT: {
            localStorage.clear();
            return {
                ...state,
            };
        }
        default: {
            return appReducer(history)(state, action);
        }
    }
};
