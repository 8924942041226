import React, {useState, SetStateAction, Dispatch, useEffect, useRef, MutableRefObject} from "react";
import {
    DataGrid,
    GridColDef,
    GridCallbackDetails,
    GridRowId,
    GridInitialState,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridPaginationModel,
} from "@mui/x-data-grid";
import {
    Toolbar as ToolbarBase,
    Typography,
    IconButton,
    Tooltip,
    Grid2 as GridBase,
    List,
    ListItem,
    ListItemText,
    Divider,
    Chip,
    Popper,
    Paper,
    ButtonGroup,
    Button,
} from "@mui/material";
import {alpha, Theme, useTheme} from "@mui/material/styles";
import {Edit, DeleteForever, AddCircle, ExpandCircleDown} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";
import {UnknownAction} from "redux";
import {NavigateFunction, useNavigate} from "react-router-dom";
import Dialog from "./Dialog";
import Box from "./Box";
import {CheckListItem} from "../../store/types/confType";

interface GridProps{
    rows:any[]
    height:number
    columns:GridColDef[]
    paginationModel:GridPaginationModel
    pageSizeOptions:number[]
    label:string
    initialState?:GridInitialState
    onAddClick?:(args:React.MouseEvent<HTMLElement>) => void
    onEditClick?:(args:React.MouseEvent<HTMLElement>) => void
    onDeleteClick?:(args:React.MouseEvent<HTMLElement>) => void
    path?:string
}

interface RenderNameProps extends GridRenderCellParams<any> {
    gridRow:any
    setGridRow:Dispatch<SetStateAction<any>>
    isMD:boolean
    path?:string
}

interface ConstructChipsProps{
    values:CheckListItem[]|string[]
    wrap?:boolean
    width?:number
}

/**
 * isOverflown
 * @param {Element} element
 * @return {boolean}
 */
const isOverflown=(element: Element):boolean => (
    element.scrollHeight>element.clientHeight || element.scrollWidth>element.clientWidth
);

/**
 * ConstructChips
 * @param {ConstructChipsProps} props
 * @return {React.ReactElement}
 */
function ConstructChips(props:ConstructChipsProps):React.ReactElement {
    const wrapper:MutableRefObject<HTMLDivElement|null>=useRef<HTMLDivElement|null>(null);
    const cellValue:MutableRefObject<any>=useRef(null);
    const cellDiv:MutableRefObject<HTMLDivElement|null>=useRef<HTMLDivElement|null>(null);
    const [anchorEl, setAnchorEl]:[null|HTMLElement, Dispatch<SetStateAction<null|HTMLElement>>]=useState<null|HTMLElement>(null);
    const [showPopper, setShowPopper]:[boolean, Dispatch<SetStateAction<boolean>>]=React.useState(false);

    /**
     * onMouseEnter
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onMouseEnter=(args:React.MouseEvent):void => {
        setAnchorEl(cellDiv.current);
        setShowPopper(isOverflown(cellValue?.current));
    };

    /**
     * onMouseLeave
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onMouseLeave=(args:React.MouseEvent):void => {
        setAnchorEl(null);
    };

    const chips=(
        props.values.map((item:CheckListItem|string) => {
            let key:CheckListItem|string;
            if (typeof item==="object" && "key" in item) key=item.key;
            else key=item;
            return (
                <Chip sx={{margin: "2px"}} key={key} color="primary" size="small" label={key} />
            );
        })
    );

    return (
        <Box
            ref={wrapper}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            sx={{
                alignItems: "center",
                lineHeight: "24px",
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: "100%",
                    display: "block",
                    position: "absolute",
                    top: 0,
                }}
            />
            {/* chips in wrap & chips in no-wrap under mobile view */}
            <Box
                ref={cellValue}
                sx={props.wrap?{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    padding: "4px",
                }:undefined}
            >
                {chips}
            </Box>
            {/* chips in popper view */}
            {showPopper && (
                <Popper
                    open={anchorEl!==null}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    sx={{
                        width: `${props.width}px`,
                    }}
                >
                    <Paper
                        elevation={1}
                    >
                        {chips}
                    </Paper>
                </Popper>
            )}
        </Box>
    );
}

/**
 * RenderList
 * @param {GridRenderCellParams<string>} cellParams
 * @return {React.ReactElement}
 */
function RenderList(cellParams: GridRenderCellParams<any>):React.ReactElement {
    if (!Array.isArray(cellParams.value)) return <div />;
    return <ConstructChips values={cellParams.value} width={cellParams.colDef.width} wrap />;
}

/**
 * RenderName
 * @param {RenderNameProps} cellParams
 * @return {React.ReactElement}
 */
function RenderName(cellParams:RenderNameProps):React.ReactElement {
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const navigate:NavigateFunction=useNavigate();

    /**
     * onExpandClick
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const onExpandClick=(args:React.MouseEvent<HTMLElement>):void => {
        cellParams.setGridRow(cellParams.row);
        dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: "GRID_ROW"});
    };

    /**
     * onGridItemClick
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const onGridItemClick=(args:React.MouseEvent<HTMLElement>):void => {
        if (cellParams.path) navigate(cellParams.path, {state: cellParams.row});
    };

    /**
     * onCloseAction
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const onCloseAction=(args:React.MouseEvent<HTMLElement>):void => {
        cellParams.setGridRow(null);
    };

    let nameField=(
        <GridBase wrap="nowrap" container direction="row" justifyContent="space-between" alignItems="center">
            <GridBase sx={{textOverflow: "ellipsis", overflow: "hidden", width: "100%"}}>{cellParams.value}</GridBase>
            {cellParams.isMD && <GridBase><IconButton onClick={onExpandClick}><ExpandCircleDown /></IconButton></GridBase>}
        </GridBase>
    );

    // if path exists make it clickable row
    if (cellParams.path) {
        nameField=(
            <ButtonGroup variant="contained" sx={{width: "calc(100% + 20px)"}} {... cellParams.isMD && {size: "large"}}>
                <Button
                    onClick={onGridItemClick}
                    sx={{
                        width: "100%",
                        paddingLeft: "10px",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        display: "inline-block",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textAlign: "left",
                        ...(cellParams.isMD && {padding: "12px"}),
                    }}
                >
                    {cellParams.value}
                </Button>
                {cellParams.isMD && <Button size="small" onClick={onExpandClick} sx={{bgcolor: grey[700]}}><ExpandCircleDown /></Button>}
            </ButtonGroup>
        );
    }

    return (
        <Box sx={{width: "100%", ...(cellParams.path && {marginLeft: "-10px"})}}>
            {/* name field */}
            {nameField}
            {/* GRID_ROW view */}
            {cellParams?.gridRow?.id===cellParams.row.id && (
                <Dialog
                    id="GRID_ROW"
                    label={cellParams.gridRow.name}
                    onCloseAction={onCloseAction}
                    content={(
                        <List>
                            {Object.keys(cellParams.gridRow).sort((a:string, b:string) => a.localeCompare(b)).map((key:string) => {
                                if (["id", "name"].includes(key)) return null;
                                let content=<Box>{cellParams.gridRow[key]}</Box>;
                                if (Array.isArray(cellParams.gridRow[key])) content=<ConstructChips values={cellParams.gridRow[key]} />;
                                return (
                                    <Box key={key}>
                                        <ListItem key={`${key}-item`} disablePadding>
                                            <ListItemText disableTypography primary={key.charAt(0).toUpperCase()+key.slice(1)} secondary={content} />
                                        </ListItem>
                                        <Divider key={`${key}-divider`} />
                                    </Box>
                                );
                            })}
                        </List>
                    )}
                />
            )}
        </Box>
    );
}

/**
 * Grid
 * @return {React.ReactElement}
 */
function Grid(props:GridProps):React.ReactElement {
    const [paginationModel, setPaginationModel]:[GridPaginationModel, Dispatch<SetStateAction<GridPaginationModel>>]=useState(props.paginationModel);
    const theme:Theme=useTheme();
    const isMD:boolean=useMediaQuery(theme.breakpoints.down("md"));
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const currentRow:any=useSelector((state:any) => state.conf.currentRow);
    const [columns, setColumns]:[any, Dispatch<SetStateAction<any>>]=useState([]);
    const [gridRow, setGridRow]:[any, Dispatch<SetStateAction<any>>]=useState(null);

    // resolving "Name" field
    useEffect(() => {
        // cloning columns
        let cols:any=[...props.columns];
        let nameFieldIdx=-1;
        // find name field
        const nameField={...(cols.find((i:any, idx:number) => {
            if (i.field==="name") nameFieldIdx=idx;
            return i.field==="name";
        }))};
        // modify columns definition
        nameField.renderCell=(renderCellParams:GridRenderCellParams<any>) => <RenderName path={props.path} isMD={isMD} gridRow={gridRow} setGridRow={setGridRow} {...renderCellParams} />;
        // display only "id" and "name" fields if mobile
        if (isMD) cols=props.columns.filter((i:any) => ["id", "name"].includes(i.field));
        // setting name field
        cols[nameFieldIdx]=nameField;
        // update columns
        setColumns(cols);
    }, [isMD, gridRow, props.columns, props.path]);

    // resolving list field
    useEffect(() => {
        if (props.rows.length!==0) {
            // resolve keys of array type
            Object.keys(props.rows[0]).forEach((key:string, index:number) => {
                if (Array.isArray(props.rows[0][key])) {
                    const field=props.columns.filter((item:any) => item.field===key);
                    if (field.length===1) field[0].renderCell=RenderList;
                }
            });
        }
    }, [props.rows, props.columns]);

    /**
     * onPaginationModelChange
     * @param {GridPaginationModel} model
     * @param {GridCallbackDetails} details
     * @return {void}
     */
    const onPaginationModelChange=(model: GridPaginationModel, details: GridCallbackDetails):void => setPaginationModel(model);

    /**
     * onRowSelectionModelChange
     * @param {GridRowSelectionModel} params
     * @param {GridCallbackDetails} details
     * @return {void}
     */
    const onRowSelectionModelChange=(newSelectionModel: GridRowSelectionModel, details: GridCallbackDetails):void => {
        const rowId:GridRowId[]=newSelectionModel.filter((newId:GridRowId) => currentRow?.id!==newId);
        const selectedRow=props.rows.filter((row:any) => row.id===rowId[0]);
        dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: selectedRow[0]||null});
    };

    /**
     * Toolbar
     * @return {React.ReactElement}
     */
    const toolbar=():React.ReactElement => (
        <ToolbarBase
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                ...(currentRow && {
                    bgcolor: (t:Theme) => alpha(t.palette.primary.main, t.palette.action.activatedOpacity),
                }),
            }}
        >
            {currentRow? (
                <Typography sx={{flex: "1 1 100%"}} color="inherit" variant="subtitle1" component="div">Selected</Typography>
            ) : (
                <Typography sx={{flex: "1 1 100%"}} variant="h6" component="div">{props.label}</Typography>
            )}
            {currentRow? (
                <Box sx={{display: {xs: "none", md: "flex"}}}>
                    {props.onDeleteClick && <Tooltip title="Delete"><IconButton onClick={props.onDeleteClick}><DeleteForever /></IconButton></Tooltip>}
                    {props.onEditClick && <Tooltip title="Edit"><IconButton onClick={props.onEditClick}><Edit /></IconButton></Tooltip>}
                </Box>
            ) : (
                props.onAddClick && <Tooltip sx={{display: {xs: "none", md: "inherit"}}} title="Add"><IconButton onClick={props.onAddClick}><AddCircle /></IconButton></Tooltip>
            )}
        </ToolbarBase>
    );
    return (
        <Box sx={{height: `${props.height}px`}}>
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: "none",
                    },
                }}
                initialState={props.initialState}
                density={isMD?"standard":"compact"}
                checkboxSelection={props.onDeleteClick!==undefined || props.onEditClick!==undefined}
                disableRowSelectionOnClick
                rows={props.rows}
                columns={columns}
                pageSizeOptions={props.pageSizeOptions}
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
                onRowSelectionModelChange={onRowSelectionModelChange}
                rowSelectionModel={currentRow?[currentRow.id]:[]}
                hideFooterSelectedRowCount
                slots={{toolbar}}
            />
        </Box>
    );
}

export default Grid;
