import React, {Dispatch} from "react";
import {
    Dialog as DialogBase,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Typography,
    styled,
    DialogTitle as DialogTitleBase,
    DialogContent,
    DialogActions,
} from "@mui/material/";
import {grey} from "@mui/material/colors";
import {Close} from "@mui/icons-material";
import {TransitionProps} from "@mui/material/transitions";
import {Theme, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";
import {UnknownAction} from "redux";
import {IForm} from "./useForm";
import Box from "./Box";
import {DialogId} from "../../store/types/confType";

const StyledDialog = styled(DialogBase)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    children?:React.ReactNode;
    onClose:(args:React.MouseEvent<HTMLElement>) => void;
}

  interface DialogProps{
    id:string
    label:string
    onPrimaryAction?:(args:React.MouseEvent<HTMLElement>) => void
    onPrimaryLabel?:string
    onCloseAction?:(args:React.MouseEvent<HTMLElement>) => void
    content:React.ReactNode
    form?:IForm
    isLoading?:boolean
}

/**
 * DialogTitle
 * @param {DialogTitleProps} props
 * @return {React.ReactElement}
 */
function DialogTitle(props: DialogTitleProps):React.ReactElement {
    return (
        <DialogTitleBase sx={{m: 0, p: 2}}>
            {props.children}
            {props.onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{position: "absolute", right: 8, top: 8, color: (theme:Theme) => theme.palette.grey[500]}}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitleBase>
    );
}

const Transition=React.forwardRef((props:TransitionProps & {children:React.ReactElement}, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
));

/**
 * Dialog
 * @return {React.ReactElement}
 */
function Dialog(props:DialogProps):React.ReactElement {
    const theme:Theme=useTheme();
    const isMD:boolean=useMediaQuery(theme.breakpoints.down("md"));
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const dialogId:DialogId=useSelector((state:any) => state.conf.dialogId);

    /**
     * onClose
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const onClose= (args:React.MouseEvent<HTMLElement>):void => {
        if (props.onCloseAction) props.onCloseAction(args);
        dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: "NONE"});
    };

    let content:React.ReactElement=(
        <Box>
            <DialogTitle onClose={onClose}>{props.label}</DialogTitle>
            <DialogContent dividers>{props.content}</DialogContent>
            {props.onPrimaryAction && (
                <DialogActions>
                    <Button
                        disabled={props.isLoading||false}
                        autoFocus
                        type={props.form?"submit":"button"}
                        color="inherit"
                        onClick={!props.form?props.onPrimaryAction:undefined}
                    >
                        {props.onPrimaryLabel}
                    </Button>
                </DialogActions>
            )}
        </Box>
    );

    if (isMD) {
        content=(
            <Box>
                <AppBar sx={{position: "relative"}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close"><Close /></IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">{props.label}</Typography>
                        {props.onPrimaryAction && (
                            <Button
                                sx={{"&:disabled": {color: grey[500]}}}
                                disabled={props.isLoading||false}
                                autoFocus
                                type={props.form?"submit":"button"}
                                onClick={!props.form?props.onPrimaryAction:undefined}
                                color="inherit"
                            >
                                {props.onPrimaryLabel}
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>
                <DialogContent>{props.content}</DialogContent>
            </Box>
        );
    }

    return (
        <Box>
            {isMD && (
                <DialogBase
                    fullScreen={isMD}
                    open={props.id===dialogId}
                    onClose={onClose}
                    TransitionComponent={Transition}
                >
                    {props.form && props.onPrimaryAction?props.form.withForm(content, props.onPrimaryAction):content}
                </DialogBase>
            )}
            {!isMD && (
                <StyledDialog
                    fullWidth
                    maxWidth="sm"
                    onClose={onClose}
                    open={props.id===dialogId}
                >
                    {props.form && props.onPrimaryAction?props.form.withForm(content, props.onPrimaryAction):content}
                </StyledDialog>
            )}
        </Box>
    );
}

export default Dialog;
