import {initializeApp} from "firebase/app";
import {Auth, getAuth} from "firebase/auth";
import {Firestore, getFirestore} from "firebase/firestore";
import {FirebaseStorage, getStorage} from "firebase/storage";
import moment from "moment";
import Joi from "joi";

const FIREBASE_CONFIG:object={
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const COLLECTIONS:any={
    // USER_DETAIL: "user-detail",
    // nutrition collections
    DIET: "diet",
    SUPPLEMENT: "supplement",
    MEAL: "meal",
    // activity collections
    EXERCISE: "exercise",
    WORKOUT: "workout",
    // daily timeline
    LOG: "log",
};

const FIREBASE_APP=initializeApp(FIREBASE_CONFIG);

export const auth:Auth=getAuth(FIREBASE_APP);

export const firestore:Firestore=getFirestore(FIREBASE_APP);

export const storage:FirebaseStorage=getStorage(FIREBASE_APP);

export const APP_PATHS={
    HOME: "/",
    SIGNIN: "/signin",
    ABOUT: "/about",
    ROUTINE: "/routine",
    WORKOUT: "/workout",
    NUTRITION: "/nutrition",
    WORKOUT_DETAIL: "/workout/detail",
    NUTRITION_DETAIL: "/nutrition/detail",
    // PROFILE: "/profile",
};

export const PASSWORD_REGEXP:any=/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const SNACK_PROVIDER_CONFIG:any={
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    dense: true,
};

export const SUPPLEMENT_TYPE_OPTIONS:string[]=["Pre-Workout", "Post-Workout", "Protein", "Vitamins", "Fat-Burner"];
export const MEAL_MEASUREMENT_OPTIONS:string[]=["ounce", "gram", "unit", "protein-scoop"];
export const DIET_TYPE_OPTIONS:string[]=["Low-Carb", "80/20", "Keto", "Vegan"];
export const EXERCISE_TYPE_OPTIONS:string[]=[
    "Cardio",
    "Olympic Weightlifting",
    "Plyometrics",
    "Powerlifting",
    "Strength",
    "Stretching",
    "Strongman",
];
export const EXERCISE_EQUIPMENT_OPTIONS:string[]=[
    "Foam Roll",
    "Barbell",
    "Kettlebells",
    "Body Only",
    "Machine",
    "Cable",
    "Medicine Ball",
    "Dumbbell",
    "Plates",
    "None",
    "E-Z Curl Bar",
    "Other",
    "Exercise Ball",
];
export const EXERCISE_MUSCLE_GROUP_OPTIONS:string[]=[
    "Chest",
    "Forearms",
    "Lats",
    "Middle Back",
    "Lower Back",
    "Neck",
    "Quadriceps",
    "Hamstrings",
    "Calves",
    "Triceps",
    "Traps",
    "Shoulders",
    "Abdominals",
    "Glutes",
    "Biceps",
    "Adductors",
    "Abductors",
];

export const JOI_DATE_FORMAT="MM-DD-YYYY";
export const JOI_MIN_DATE=moment("01-01-2000", JOI_DATE_FORMAT);
export const JOI_MAX_DATE=moment();
export const JOI_MAX_CHECK_LIST_VALUE=Joi.object({value: Joi.number().min(0).max(1000)});

export {default as store} from "./configureAppStore";
export * from "./constants";
