import React from "react";
import {Navigate} from "react-router-dom";
import {APP_PATHS} from "../../config";
import {AuthProps} from "./useAuth";

interface props {
    component:React.ReactElement
    auth:AuthProps
}

/**
 * PrivateRoute
 * @param {props} props
 * @return {React.ReactElement}
 */
function PrivateRoute({component, auth}:props):React.ReactElement {
    if (auth.user) return component;
    return <Navigate to={APP_PATHS.HOME} />;
}

export default PrivateRoute;
