import React from "react";
import {Grid2 as Grid} from "@mui/material/";
import Joi from "joi";
import useForm, {IForm} from "../generics/useForm";
import {FormSchema, DialogId} from "../../store/types/confType";
import Dialog from "../generics/Dialog";
import {JOI_MIN_DATE, JOI_MAX_DATE, JOI_DATE_FORMAT} from "../../config";

export const FORM_SCHEMA:FormSchema[]=[
    {
        id: "start",
        type: "datepicker",
        label: "Start",
        joiOptions: Joi.date()
            .min(JOI_MIN_DATE.toDate())
            .max(JOI_MAX_DATE.toDate())
            .required()
            .messages({
                "any.required": "Required",
                "date.base": "Invalid Date",
                "date.min": `Min ${JOI_MIN_DATE.format(JOI_DATE_FORMAT).toString()}`,
                "date.max": `Max ${JOI_MAX_DATE.format(JOI_DATE_FORMAT).toString()}`,
            }),
    },
    {
        id: "end",
        type: "datepicker",
        label: "End",
        joiOptions: Joi.date()
            .greater(Joi.ref("start"))
            .min(JOI_MIN_DATE.toDate())
            .max(JOI_MAX_DATE.toDate())
            .required()
            .messages({
                "any.required": "Required",
                "date.base": "Invalid Date",
                "date.min": `Min ${JOI_MIN_DATE.format(JOI_DATE_FORMAT).toString()}`,
                "date.max": `Max ${JOI_MAX_DATE.format(JOI_DATE_FORMAT).toString()}`,
                "date.greater": "End must be greater than Start",
            }),
    },
];

interface RangeProps{
    onSubmit:(data:any) => void
    onClose:() => void
    currentRow:any
    dialogId: DialogId
    label: string
}

/**
 * Range
 * @return {React.ReactElement}
 */
function Range(props:RangeProps):React.ReactElement {
    const form:IForm=useForm(FORM_SCHEMA, props.currentRow);
    /**
     * constructLayout
     * @param {React.ReactElement[]} fields
     * @return {React.ReactElement}
     */
    const constructLayout=(fields:React.ReactElement[]):React.ReactElement => (

        <Dialog
            id={props.dialogId}
            label={props.label}
            onPrimaryAction={props.onSubmit}
            onPrimaryLabel="Run"
            onCloseAction={props.onClose}
            form={form}
            content={(
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                        <Grid container rowSpacing={1} columnSpacing={{xs: 2, sm: 2, md: 2}}>
                            {fields.map((item:React.ReactElement) => <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}} key={item.key}>{item}</Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    );
    return constructLayout(form.fields);
}

export default Range;
