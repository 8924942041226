import React, {useState} from "react";
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Grid2 as Grid,
    Divider,
    TextField,
    Checkbox,
    Paper,
    Skeleton,
} from "@mui/material";
import Box from "./Box";
import {JOI_MAX_CHECK_LIST_VALUE} from "../../config";
import {CheckListItem} from "../../store/types/confType";

interface CheckListProps{
    id:string
    label:string
    options:any[]
    itemKeys:CheckListItem[]
    helperText:string
    error:boolean
    value:CheckListItem[]
    onChange:(value:CheckListItem, override?:boolean) => (_:any) => void
}

/**
 * CheckList
 * @return {React.ReactElement}
 */
function CheckList(props:CheckListProps):React.ReactElement {
    const items:React.ReactElement[]=[];
    const [options, setOptions]=useState(props.options);

    /**
     * onSearchChange
     * @param {React.ChangeEvent<HTMLInputElement>} args
     * @return {void}
     */
    const onSearchChange=(args:React.ChangeEvent<HTMLInputElement>):void => {
        const newOptions=props.options.filter((record:any) => record.name.toLowerCase().includes((args.target.value).toLowerCase()));
        setOptions(newOptions);
    };

    /**
     * resolveItem
     * @param {string} option
     * @return {CheckListItem}
     */
    const resolveItem=(option:string):CheckListItem => props.value.find((chkItem:CheckListItem) => chkItem.key===option) as CheckListItem;

    /**
     * onTextChange
     * @param {React.ChangeEvent<HTMLInputElement>} args
     * @return {void}
     */
    const onTextChange=(chkItem:CheckListItem, key:string) => (args:React.ChangeEvent<HTMLInputElement>):void => {
        const item={...chkItem};
        item.value[key]=args.target.value;
        props.onChange(item, true)(args);
    };
    /**
     * listItemText
     * @param {string} option
     * @return {React.ReactElement}
     */
    const listItemText=(option:string):React.ReactElement => {
        const item:CheckListItem=resolveItem(option);
        return (
            <Grid sx={{display: {xs: "block", sm: "flex"}}} container columnSpacing={1} wrap="nowrap" direction="row" justifyContent="flex-start" alignItems="center">
                {/* label */}
                <Grid sx={{flexGrow: 1}}>
                    <Typography sx={{color: item===undefined?"gray":"inherit"}} noWrap variant="subtitle1">{option}</Typography>
                </Grid>
                <Grid>
                    {/* item keys */}
                    <Grid container columnSpacing={1} wrap="nowrap" direction="row" justifyContent="flex-start" alignItems="center">
                        {props.itemKeys.map((itemKey:CheckListItem) => {
                            const schema=JOI_MAX_CHECK_LIST_VALUE.validate({value: item?.value[itemKey.key]});
                            return (
                                <Grid key={itemKey.key}>
                                    <TextField
                                        onChange={onTextChange(item, itemKey.key)}
                                        disabled={item===undefined}
                                        sx={{maxWidth: "80px", minWidth: "80px"}}
                                        label={itemKey.key}
                                        size="small"
                                        value={item?.value[itemKey.key]||""}
                                        error={"error" in schema}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    // pushing options
    options.forEach((record:any, index:number) => {
        // set divider
        if (index!==0) items.push(<Divider key={`${record.name}-divider`} />);
        const item:CheckListItem=resolveItem(record.name);
        // initialize CheckListItem
        const value:any={};
        props.itemKeys.forEach((itemKey:CheckListItem) => { value[itemKey.key]=itemKey.value.toString(); });

        items.push(
            <ListItem key={record.name}>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={item!==undefined}
                        onChange={props.onChange(item||{key: record.name, value} as CheckListItem)}
                    />
                </ListItemIcon>
                <ListItemText primary={listItemText(record.name)} />
            </ListItem>,
        );
    });

    /**
     * constructMacros
     * @return {React.ReactElement}
     */
    const constructMacros=():React.ReactElement|null => {
        if (props.id!=="meals") return null;

        const units:any={calories: "cals", carbohydrates: "g", protein: "g", fat: "g"};
        const macros:any={calories: 0, carbohydrates: 0, protein: 0, fat: 0};
        const content:React.ReactElement[]=[];

        // resolve macros
        props.value.forEach((chkItem:CheckListItem) => {
            const meal=props.options.find((m:any) => m.name===chkItem.key) as any;
            Object.keys(macros).forEach((key:string) => {
                macros[key]+=meal[key]*chkItem.value.qty;
            });
        });
        // round values
        Object.keys(macros).forEach((key:string) => {
            macros[key]=Math.round(macros[key]*10)/10;
        });

        // resolve content
        Object.keys(macros).forEach((key:string) => {
            content.push(
                <Grid size={{xs: 6, sm: 3, md: 3, lg: 3, xl: 3}} key={key}>
                    <Paper elevation={1} sx={{padding: "6px", textAlign: "center"}}>
                        <Typography variant="subtitle2">{`${key[0].toUpperCase()}${key.slice(1).toLowerCase()} `}</Typography>
                        {props.error
                            ?<Skeleton variant="rounded" height={22} />
                            :<Typography variant="subtitle2" sx={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{`${macros[key]} (${units[key]})`}</Typography>}
                    </Paper>
                </Grid>,
            );
        });

        return (
            <Grid sx={{padding: "16px", paddingTop: "0px"}} container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                {content}
            </Grid>
        );
    };

    return (
        <Box>
            <Box
                sx={{border: `1px solid ${props.error?"#d32f2f":"#e0e0e0"}`, borderRadius: "4px"}}
            >
                {/* search field */}
                <TextField
                    placeholder="Search..."
                    hiddenLabel
                    variant="standard"
                    size="small"
                    sx={{padding: "16px", paddingBottom: "6px", width: "100%"}}
                    onChange={onSearchChange}
                />
                {/* macros */}
                {constructMacros()}
                {/* list */}
                <List
                    dense
                    sx={{paddingTop: "0px", paddingBottom: "0px", maxHeight: "300px", minHeight: "300px", overflow: "auto"}}
                >
                    {items}
                </List>
            </Box>
            {/* helper text */}
            <Typography
                sx={{color: props.error?"#d32f2f":"inherit", marginLeft: "14px"}}
                noWrap
                variant="caption"
            >
                {props.helperText}
            </Typography>
        </Box>
    );
}

export default CheckList;
