import {signInWithEmailAndPassword, UserCredential, signOut as signOutBase} from "firebase/auth";
import {auth} from "../../config";
import {Payload} from "../../store/types/confType";

const USER_SIGN_OUT:Payload={data: {code: "user/sign-out", message: "You have successfully signed out!"}};

/**
 * signIn
 * @param {any} payload
 * @return {Promise<Payload>}
 */
const signIn= async (payload:any):Promise<Payload> => (
    signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then((userCredential:UserCredential) => ({data: userCredential.user}))
        .catch((error:any) => ({error: {code: error.code, message: error.message}}))
);

/**
 * signOut
 * @return {Promise<Payload>}
 */
const signOut= async ():Promise<Payload> => (
    signOutBase(auth)
        .then(() => (USER_SIGN_OUT))
        .catch((error:any) => ({error: {code: error.code, message: error.message}}))
);

export const UserService:any ={
    signIn,
    signOut,
};
