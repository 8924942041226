import React from "react";
import {Grid2 as Grid, Paper} from "@mui/material/";
import Joi from "joi";
import {useTheme, Theme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useForm, {IForm} from "../generics/useForm";
import {FormSchema} from "../../store/types/confType";
import IPhone from "../layout/IPhone";

export const FORM_SCHEMA:FormSchema[]=[
    {
        id: "name",
        type: "text",
        label: "Name",
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Set a Name",
            }),
    },
    {
        id: "email",
        type: "text",
        label: "Email",
        joiOptions: Joi.string().email({tlds: {allow: false}}).lowercase().required()
            .messages({
                "string.empty": "Set an Email",
                "string.email": "Ivalid Email",
            }),
    },
    {
        id: "message",
        type: "text",
        label: "Message",
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Write a Message",
            }),
        textAreaOptions: {
            rows: 5,
            placeholder: "Message...",
        },
    },
    {
        id: "send",
        type: "submit",
        label: "Send",
    },
];

interface ContactProps{
    onSubmit:(onReset:any) => (data:any) => void
}

/**
 * Contact
 * @return {React.ReactElement}
 */
function Contact(props:ContactProps):React.ReactElement {
    const theme:Theme=useTheme();
    const isLG =useMediaQuery(theme.breakpoints.down("lg"));
    const form:IForm=useForm(FORM_SCHEMA);
    /**
     * constructLayout
     * @param {React.ReactElement[]} fields
     * @return {React.ReactElement}
     */
    const constructLayout=(fields:React.ReactElement[]):React.ReactElement => (
        <Grid container spacing={{xs: 1, md: 1, lg: 4, xl: 12}} direction="row" justifyContent="center" alignItems="center">
            {/* form paper */}
            <Grid size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 6}}>
                <Paper elevation={4} sx={{padding: 3, ...(!isLG && {maxWidth: "600px", float: "right"})}}>
                    <Grid container rowSpacing={1} columnSpacing={{xs: 2, sm: 2, md: 2}}>
                        {fields.map((item:React.ReactElement) => <Grid sx={item.key==="send"?{maxWidth: "120px !important"}:undefined} size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}} key={item.key}>{item}</Grid>)}
                    </Grid>
                </Paper>
            </Grid>
            {/* IPhone */}
            <Grid sx={{textAlign: "center"}} size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 6}}>
                <IPhone />
            </Grid>
        </Grid>
    );
    return form.withForm(constructLayout(form.fields), props.onSubmit(form.onReset));
}

export default Contact;
