import React from "react";
import {Grid2 as Grid} from "@mui/material/";
import {Timestamp} from "firebase/firestore";
import moment from "moment";
import {DialogId} from "../../store/types/confType";
import Dialog from "../generics/Dialog";
import {toStringDate} from "../../handler/firebase";

interface DeleteProps{
    currentRow:any
    onDelete: (args:React.MouseEvent<HTMLElement>) => void
    label: string
    dialogId: DialogId
}
/**
 * Delete
 * @return {React.ReactElement}
 */
function Delete(props:DeleteProps):React.ReactElement {
    if (!props.currentRow) return <div />;
    return (
        <Dialog
            id={props.dialogId}
            label={props.label}
            onPrimaryAction={props.onDelete}
            onPrimaryLabel="Delete"
            content={(
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                        {"Are you sure you want to delete "}
                        <b>
                            {(props.currentRow.name instanceof Timestamp)?toStringDate(moment(props.currentRow.name.toDate())):props.currentRow.name}
                        </b>
                        ?
                    </Grid>
                </Grid>
            )}
        />
    );
}

export default Delete;
