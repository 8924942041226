import React, {Dispatch, SetStateAction, useState} from "react";
import {useDispatch} from "react-redux";
import {Node} from "slate";
import {UnknownAction} from "redux";
import {FixedSizeList, ListChildComponentProps} from "react-window";
import AutoSizer, {Size} from "react-virtualized-auto-sizer";
import {
    Grid2 as Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    IconButton,
    Divider,
    Chip,
    Typography,
    Menu,
    MenuItem,
    Fab,
    Zoom,
    Paper,
    Link,
    Skeleton,
    LinearProgress,
    Collapse,
} from "@mui/material/";
import {grey, red} from "@mui/material/colors";
// icons
import {
    MonitorWeight,
    CalendarMonth,
    FoodBank,
    MoreVert,
    Fullscreen,
    Comment,
    Restaurant,
    FitnessCenter,
    ImageNotSupported,
} from "@mui/icons-material";
import Dialog from "../generics/Dialog";
import {isDateFalls, toStringDate} from "../../handler/firebase";
import {Util} from "../../services/firebase";
import Box from "../generics/Box";

interface RoutineListViewProps{
    currentRow:any
    data:any
    actions:any[]
    isMD:boolean
    isSM:boolean
    isLoading:boolean
}

interface ListItemViewProps{
    fixedSizeList:ListChildComponentProps
    currentRow:any
    actions:any[]
    isMD:boolean
    isSM:boolean
    isLoading:boolean
    setListItem: Dispatch<SetStateAction<any>>
    setImageURL: Dispatch<SetStateAction<any>>
}

/**
 * ListItemView
 * @return {React.ReactElement}
 */
function ListItemView(props:ListItemViewProps):React.ReactElement {
    const item=props.fixedSizeList.data?props.fixedSizeList.data[props.fixedSizeList.index]:undefined;
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const [menuAnchorEl, setMenuAnchorEl]:[null | HTMLElement, Dispatch<SetStateAction<null | HTMLElement>>]=React.useState<null | HTMLElement>(null);

    /**
     * onListItemClick
     * @param {number} value
     * @return {void}
     */
    const onListItemClick=(value:any) => (args:React.MouseEvent<HTMLElement>) => {
        dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: (props.currentRow?.id===value.id)?null:value});
    };

    /**
     * onMoreClick
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const onMoreClick=(args:React.MouseEvent<HTMLButtonElement>):void => {
        setMenuAnchorEl(args.currentTarget);
    };

    /**
     * onMoreClose
     * @return {void}
     */
    const onMoreClose=(event?:any) => (args:React.MouseEvent<HTMLElement>):void => {
        setMenuAnchorEl(null);
        if (event) event();
    };

    const secondaryProps=[
        {id: `${item?.id}-secondary-weight`, label: item?.weight?(Math.round((item.weight)*10)/10).toFixed(1):null, icon: <MonitorWeight />},
        {id: `${item?.id}-secondary-diet`, label: item?.diet, icon: <FoodBank />},
        {id: `${item?.id}-secondary-date`, label: item?.name && toStringDate(item.name), icon: <CalendarMonth />},
    ];

    // adding comment chip and serialize
    // serializing is needed to resolve if comment is empty or not
    if (item?.comment && JSON.parse(item?.comment).map((n:Node) => Node.string(n)).join("").length!==0) {
        secondaryProps.unshift({id: `${item?.id}-secondary-comment`, label: undefined, icon: <Comment sx={{color: `${red.A700}!important`, marginRight: "-13px!important"}} />});
    }

    /**
     * onDetailsFabClick
     * @return {void}
     */
    const onDetailsFabClick=(args:React.MouseEvent<HTMLElement>):void => {
        props.setListItem(props.currentRow);
        Util.getFile(props.currentRow?.imageURL, props.setImageURL);
        dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: "GRID_ROW"});
    };

    const detailsFab=(props.currentRow?.id===item?.id)?(
        <Zoom in>
            <Fab
                onClick={onDetailsFabClick}
                size="small"
                color="info"
                sx={{
                    width: "30px",
                    minWidth: "30px",
                    height: "30px",
                    minHeight: "30px",
                    position: "relative",
                    bottom: {xs: 13, sm: 23, md: 23},
                    borderRadius: "0",
                    marginLeft: {sm: "-16px", md: "-16px"},
                    marginRight: {xs: "12px", sm: "16px"},
                    // opacity: 0.8,
                }}
            >
                <Fullscreen sx={{fontSize: "44px"}} />
            </Fab>
        </Zoom>
    ):null;

    const secondaryActionContent=(
        !props.isMD && props.currentRow?.id===item?.id?(
            <Zoom in>
                <Box>
                    <IconButton edge="end" onClick={onMoreClick}><MoreVert /></IconButton>
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={menuAnchorEl!==null}
                        onClose={onMoreClose()}
                    >
                        {props.actions.slice(1).map((action:any, index:number) => (
                            <MenuItem disabled={[0, 1].includes(index) && !isDateFalls(item?.name)} key={`${action.name}-menu-item`} onClick={onMoreClose(action.onClick)}>{action.name}</MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Zoom>
        )
            :null
    );

    /**
     * secondaryContent
     * @param {any[]} subitems
     * @return {React.ReactElement}
     */
    const secondaryContent=(subitems:any[]):React.ReactElement => (
        <Grid container wrap="nowrap" direction="row" justifyContent="flex-end" alignItems="flex-start">
            {subitems.map((subItem:any) => {
                let chip=<Chip label={subItem.label} variant="outlined" color="primary" size="small" icon={subItem.icon} />;
                if (subItem.label===null) return null;
                if (!item) chip=<Skeleton sx={{width: 40}} />;
                return <Grid sx={{paddingLeft: "4px"}} key={subItem.id}>{chip}</Grid>;
            })}
        </Grid>
    );

    let workoutLabel="NONE";
    if (item?.workout) workoutLabel=item.workout.toUpperCase();

    return (
        <Box style={props.fixedSizeList.style}>
            <ListItem
                secondaryAction={item && secondaryActionContent}
                disablePadding={!props.isSM}
                sx={item && props.currentRow?.id===item.id?{
                    bgcolor: grey[300],
                    paddingLeft: (props.isSM?"0px":"auto"),
                    paddingRight: (props.isSM?"0px":"auto"),
                }:{
                    paddingLeft: (props.isSM?"0px":"auto"),
                    paddingRight: (props.isSM?"0px":"auto"),
                }}
            >
                <ListItemButton disabled={props.isLoading} role={undefined} onClick={item?onListItemClick(item):undefined} dense sx={props.isSM?{padding: "0px"}:undefined}>
                    {item && detailsFab}
                    <ListItemText
                        disableTypography
                        primary={(
                            <Grid wrap="nowrap" container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid><Typography noWrap variant="h6">{item?workoutLabel:<Skeleton sx={{width: 140}} />}</Typography></Grid>
                                {props.isSM && (<Grid><Typography variant="h6">{secondaryContent(secondaryProps.filter((i:any) => ["date", "comment"].some((v:string) => i.id.includes(v))))}</Typography></Grid>)}
                            </Grid>
                        )}
                        secondary={!props.isSM && secondaryContent(secondaryProps)}
                    />
                </ListItemButton>
            </ListItem>
            <Divider />
        </Box>
    );
}

/**
 * RoutineListView
 * @return {React.ReactElement}
 */
function RoutineListView(props:RoutineListViewProps):React.ReactElement {
    const OFFSET=152;
    const [listItem, setListItem]:[any, Dispatch<SetStateAction<any>>]=useState(null);
    const [imageURL, setImageURL]:[any, Dispatch<SetStateAction<any>>]=useState(null);
    const [listHeight, setListHeight]:[number, Dispatch<SetStateAction<number>>]=useState(window.innerHeight-OFFSET);

    /**
     * onCloseAction
     * @return {void}
     */
    const onCloseAction=():void => {
        setListItem(null);
        setImageURL(null);
    };

    /**
     * onResize
     * @param {Size} args
     */
    const onResize=(args:Size):void => setListHeight(window.innerHeight-OFFSET);

    /**
     * withFixedSizeList
     * @param {ListChildComponentProps} fixedSizeList
     * @return {React.ReactElement}
     */
    const withFixedSizeList=(fixedSizeList:ListChildComponentProps):React.ReactElement => <ListItemView fixedSizeList={fixedSizeList} {...props} setListItem={setListItem} setImageURL={setImageURL} />;

    /**
     * resolveImageUrl
     * @param {string} url
     */
    const resolveImageUrl=(url:string):React.ReactElement => {
        // no image placeholder
        let content=<ImageNotSupported sx={{fontSize: "64px"}} />;
        // resolve image url
        if (url && imageURL) content=<img alt={`${imageURL}`} src={imageURL} width={props.isSM?"300px":"400px"} />;
        else if (url && !imageURL) return <LinearProgress />;

        return (
            <Grid container direction="column" justifyContent="center" alignItems="center">
                {/* image */}
                <Grid>
                    <Paper
                        elevation={5}
                        sx={{
                            padding: "6px",
                            marginTop: "12px",
                            width: props.isSM?"312px":"412px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            ...!url && {minHeight: props.isSM?"200px":"300px"},
                        }}
                    >
                        {content}
                    </Paper>
                </Grid>
                {/* link */}
                <Grid>{imageURL?<Link variant="caption" href={imageURL}>Full Screen</Link>:<Box sx={{width: "24px"}} />}</Grid>
            </Grid>
        );
    };

    /**
     * resolveItem
     * @param {any} item
     */
    const resolveItem=(item:any):React.ReactElement => (
        <Grid sx={{marginTop: "-10px"}} container direction="row" justifyContent="space-around" alignItems="flex-start">
            {[
                {key: "workout", icon: FitnessCenter},
                {key: "diet", icon: Restaurant},
                {key: "weight", icon: MonitorWeight},
            ].map((i:any) => (
                <Grid key={i.key}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid><i.icon fontSize="large" /></Grid>
                        <Grid><Typography noWrap sx={{maxWidth: {xs: "120px", sm: "none"}}}>{item[i.key]||"-"}</Typography></Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <Box sx={{height: `${listHeight}px`}}>
            {/* Progress Bar */}
            <Collapse in={props.isLoading}><LinearProgress color="info" /></Collapse>
            {/* LIST VIEW */}
            <AutoSizer onResize={onResize}>
                {(size:Size) => (
                    <FixedSizeList
                        className="List"
                        height={size.height as number}
                        width={size.width as number}
                        itemCount={props.data?props.data.length:10}
                        itemSize={props.isSM?56:76}
                        itemData={props.data}
                    >
                        {withFixedSizeList}
                    </FixedSizeList>
                )}
            </AutoSizer>
            {/* GRID_ROW view */}
            {listItem && (
                <Dialog
                    id="GRID_ROW"
                    label={toStringDate(listItem.name)}
                    onCloseAction={onCloseAction}
                    content={(
                        <List sx={{padding: 0}}>
                            {/* weight + diet + workout */}
                            <ListItem disablePadding><ListItemText disableTypography primary={resolveItem(listItem)} /></ListItem>
                            {/* line */}
                            <Divider />
                            {/* imageURL */}
                            <ListItem disablePadding><ListItemText disableTypography primary={resolveImageUrl(listItem.imageURL)} /></ListItem>
                        </List>
                    )}
                />
            )}
        </Box>
    );
}

export default RoutineListView;
