import React, {Dispatch, useCallback, useEffect, useState} from "react";
import {Grid2 as Grid, Typography, Chip} from "@mui/material";
import {useLocation, Location} from "react-router-dom";
import {useDispatch} from "react-redux";
import {UnknownAction} from "redux";
import Box from "../components/generics/Box";
import PieChart, {RecordType} from "../components/layout/PieChart";
import GridData from "../components/generics/Grid";
import {BaseService} from "../services/firebase";
import {FORM_SCHEMA as EXERCISE_FORM_SCHEMA} from "../components/forms/Exercise";
import {COLLECTIONS} from "../config";
import {EXERCISE_GRID_COL_DEFS} from "./Workout";

interface FormatExercises{
    muscles:RecordType[]
    breakdown: {rows: any[], columns: any}
}

/**
 * WorkoutDetail
 * @return {React.ReactElement}
 */
function WorkoutDetail():React.ReactElement {
    const {state}:Location=useLocation();
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const [exercises, setExercises]:any=useState(null);

    const isExercises=(Array.isArray(state?.exercises) && state?.exercises.length!==0);

    const getDocs=useCallback(async () => {
        const GET_CALLS_PROPS={collection: COLLECTIONS.EXERCISE, set: setExercises, formSchema: EXERCISE_FORM_SCHEMA, gridColDef: EXERCISE_GRID_COL_DEFS, in: state.exercises.map((e:any) => e.key)};
        const res=await BaseService.list(GET_CALLS_PROPS);
        if (res.error) {
            // display snack message
            dispatch({
                type: "@@CONF/NOTIFIER_ENQUEUE",
                notification: {
                    message: res.error.message,
                    options: {variant: "error", action: "DISMISS", persist: false},
                },
            });
        }
    }, [dispatch, state.exercises]);

    useEffect(() => {
        if (isExercises) getDocs();
    }, [getDocs, isExercises]);

    /**
     * formatExercises
     * @param {any} data
     * @return {FormatExercises|undefined}
     */
    const formatExercises=(data:any):FormatExercises|undefined => {
        if (!data) return undefined;
        const accumulator:any={};
        const muscles:RecordType[]=[];
        const breakdown:any={rows: [], columns: [...exercises.columns.map((e:any) => ({...e}))]};
        // breakdown - push sets & reps on indexes 3 and 4
        breakdown.columns.splice(
            3,
            0,
            {field: "sets", headerName: "Sets", width: "80"},
            {field: "reps", headerName: "Reps", width: "80"},
        );

        // iterate over exercises
        data?.rows?.forEach((item:any) => {
            // resolve exercise incoming from workout
            const exercise=state.exercises.find((e:any) => e.key===item.name);
            // breakdown push row
            breakdown.rows.push({...item, sets: exercise.value.sets, reps: exercise.value.reps});
            if (!(item.muscle_group in accumulator)) accumulator[item.muscle_group]=1;
            else if (item.muscle_group in accumulator) accumulator[item.muscle_group]+=1;
        });

        Object.keys(accumulator).forEach((key:string) => {
            muscles.push({key, value: accumulator[key]});
        });

        return {muscles, breakdown};
    };

    const data:FormatExercises|undefined=formatExercises(exercises);
    const empty=<Typography sx={{marginBottom: "8px"}} color="inherit" variant="body1">None</Typography>;

    return (
        <Box>
            <Grid
                sx={{
                    padding: {xs: "24px", md: "0px"},
                    paddingLeft: {xs: "24px", md: "160px"},
                    paddingRight: {xs: "24px", md: "160px"},
                }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
            >
                {/* name */}
                <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                    <Typography color="inherit" variant="h4">{state?.name}</Typography>
                </Grid>
                {/* exercises */}
                <Grid size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 6}}>
                    <Typography sx={{marginBottom: "8px"}} color="inherit" variant="h6">Exercises</Typography>
                    {isExercises
                        ?state?.exercises?.map((s:any) => <Chip key={s.key} sx={{marginRight: "8px", marginBottom: "8px"}} label={s.key} size="small" variant="outlined" />)
                        :empty}
                </Grid>
                {/* exercises vs. muscles */}
                <Grid size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 6}}>
                    <Typography sx={{marginBottom: "8px"}} color="inherit" variant="h6">Exercises Vs. Muscles</Typography>
                    <PieChart data={data?.muscles} />
                </Grid>
                {/* Grid Instance */}
                <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                    {isExercises
                        ?(data?.breakdown
                            && (
                                <GridData
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                id: false,
                                            },
                                        },
                                    }}
                                    label="Breakdown"
                                    rows={data.breakdown.rows}
                                    height={500}
                                    columns={data.breakdown.columns}
                                    paginationModel={{page: 0, pageSize: 25}}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                />
                            )
                        )
                        :(
                            <Box>
                                <Typography sx={{marginBottom: "8px"}} color="inherit" variant="h6">Breakdown</Typography>
                                {empty}
                            </Box>
                        )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default WorkoutDetail;
