import React, {Dispatch, useState, useEffect, useMemo, useCallback} from "react";
import {useTheme, Theme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Paper} from "@mui/material/";
import {useDispatch, useSelector} from "react-redux";
import {UnknownAction} from "redux";
import {FieldValues} from "react-hook-form";
// icons
import {FitnessCenter, SportsGymnastics, Difference, DeleteForever, Edit, Add} from "@mui/icons-material";
// components
import Box from "../components/generics/Box";
import Tab, {TabProp} from "../components/generics/Tab";
import SpeedDial from "../components/generics/SpeedDial";
import DataGrid from "../components/generics/Grid";
import WorkoutForm, {FORM_SCHEMA as WORKOUT_FORM_SCHEMA} from "../components/forms/Workout";
import ExerciseForm, {FORM_SCHEMA as EXERCISE_FORM_SCHEMA} from "../components/forms/Exercise";
import DeleteForm from "../components/forms/Delete";
import {BaseService} from "../services/firebase";
import {DialogId} from "../store/types/confType";
import {COLLECTIONS, APP_PATHS} from "../config";

const WORKOUT_GRID_COL_DEFS={
    name: {width: "260"},
    exercises: {width: "250"},
};

export const EXERCISE_GRID_COL_DEFS={
    name: {width: "260"},
    type: {width: "120"},
    equipment: {width: "120"},
    muscle_group: {width: "140"},
};

/**
 * Workout
 * @return {React.ReactElement}
 */
function Workout():React.ReactElement {
    const theme:Theme=useTheme();
    const isMD:boolean=useMediaQuery(theme.breakpoints.down("md"));
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const currentRow:any=useSelector((state:any) => state.conf.currentRow);
    const [tabIndex, setTabIndex]:any=useState(0);
    const [collection, setCollection]:any=useState(COLLECTIONS.WORKOUT);
    const [workouts, setWorkouts]:any=useState(null);
    const [exercises, setExercises]:any=useState(null);

    const GET_CALLS_PROPS=useMemo(() => (
        [
            {collection: COLLECTIONS.WORKOUT, set: setWorkouts, formSchema: WORKOUT_FORM_SCHEMA, gridColDef: WORKOUT_GRID_COL_DEFS},
            {collection: COLLECTIONS.EXERCISE, set: setExercises, formSchema: EXERCISE_FORM_SCHEMA, gridColDef: EXERCISE_GRID_COL_DEFS},
        ]
    ), []);

    /**
     * onTabChange
     * @param {any} args
     * @return {void}
     */
    const onTabChange=(index:number):void => {
        if (index===0) setCollection(COLLECTIONS.WORKOUT);
        else if (index===1) setCollection(COLLECTIONS.EXERCISE);
        dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: null});
        setTabIndex(index);
    };

    const getDocs=useCallback(async () => {
        GET_CALLS_PROPS.forEach(async (callProp:any) => {
            const res=await BaseService.list(callProp);
            if (res.error) {
                // display snack message
                dispatch({
                    type: "@@CONF/NOTIFIER_ENQUEUE",
                    notification: {
                        message: res.error.message,
                        options: {variant: "error", action: "DISMISS", persist: false},
                    },
                });
            }
        });
    }, [dispatch, GET_CALLS_PROPS]);

    useEffect(() => {
        getDocs();
    }, [getDocs]);

    // reseting current row
    useEffect(() => (
        () => {
            dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: null, dialogId: "NONE"});
        }
    ), [dispatch]);

    const actions:any= [
        {icon: <Add />, name: "Add", onClick: (args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_CREATE`})},
        {icon: <Edit />, name: "Edit", onClick: currentRow && ((args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_UPDATE`}))},
        {icon: <DeleteForever />, name: "Delete", onClick: currentRow && ((args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_DELETE`}))},
    ];

    /**
     * onCall
     * @param {FieldValues} data
     * @return {Promise<void>}
     */
    const onCall= (type:DialogId) => async (data:FieldValues):Promise<void> => {
        let res:any=null;
        // resolve service CRUD
        const serviceMapper:any={
            WORKOUT_CREATE: () => BaseService.post({collection, payload: data}),
            WORKOUT_UPDATE: () => BaseService.update({collection, payload: data, currentRow, foreignKey: {field: "workout", parentCollection: COLLECTIONS.LOG, queryOp: "=="}}),
            WORKOUT_DELETE: () => BaseService.del({collection, currentRow, foreignKey: {field: "workout", parentCollection: COLLECTIONS.LOG, queryOp: "=="}}),
            EXERCISE_CREATE: () => BaseService.post({collection, payload: data}),
            EXERCISE_UPDATE: () => BaseService.update({collection, payload: data, currentRow, foreignKey: {field: "exercises", parentCollection: COLLECTIONS.WORKOUT}}),
            EXERCISE_DELETE: () => BaseService.del({collection, currentRow, foreignKey: {field: "exercises", parentCollection: COLLECTIONS.WORKOUT}}),
        };
        // call service
        res=await serviceMapper[type]();

        if (res.data) {
            // close dialog
            dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: "NONE"});
            // display snack message
            dispatch({
                type: "@@CONF/NOTIFIER_ENQUEUE",
                notification: {
                    message: res.data.message,
                    options: {variant: "success", action: "DISMISS", persist: false},
                },
            });
            // unset selected row
            if (currentRow) dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: null});

            // re-fetch values to update grid view
            if (tabIndex===0) await BaseService.list(GET_CALLS_PROPS[0]);
            else if (tabIndex===1) {
                await BaseService.list(GET_CALLS_PROPS[1]);
                // update Workouts since foreign keys live under Workouts may have been altered
                if (type!=="EXERCISE_CREATE") await BaseService.list(GET_CALLS_PROPS[0]);
            }
        } else if (res.error) {
            // display snack message
            dispatch({
                type: "@@CONF/NOTIFIER_ENQUEUE",
                notification: {
                    message: res.error.message,
                    options: {variant: "error", action: "DISMISS", persist: false},
                },
            });
        }
    };

    const tabs:TabProp[]=[
        {
            label: "Workout",
            icon: <FitnessCenter />,
            content: (
                <Box>
                    {/* Grid Instance */}
                    {workouts && (
                        <DataGrid
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            label="Workouts"
                            rows={workouts.rows}
                            height={500}
                            columns={workouts.columns}
                            paginationModel={{page: 0, pageSize: 25}}
                            pageSizeOptions={[10, 25, 50, 100]}
                            onAddClick={actions[0].onClick}
                            onEditClick={actions[1].onClick}
                            onDeleteClick={actions[2].onClick}
                            path={APP_PATHS.WORKOUT_DETAIL}
                        />
                    )}
                    {/* Create Dialog Instance */}
                    <WorkoutForm onSubmit={onCall("WORKOUT_CREATE")} label="Add Workout" dialogId="WORKOUT_CREATE" exercises={exercises} />
                    {/* Update Dialog Instance */}
                    {currentRow && <WorkoutForm onSubmit={onCall("WORKOUT_UPDATE")} label="Edit Workout" currentRow={currentRow} dialogId="WORKOUT_UPDATE" exercises={exercises} />}
                    {/* Delete Dialog Instance */}
                    {currentRow && <DeleteForm onDelete={onCall("WORKOUT_DELETE")} label="Delete Workout" currentRow={currentRow} dialogId="WORKOUT_DELETE" />}
                </Box>
            ),
        },
        {
            label: "Exercise",
            icon: <SportsGymnastics />,
            content: (
                <Box>
                    {/* Grid Instance */}
                    {exercises && (
                        <DataGrid
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            label="Exercises"
                            rows={exercises.rows}
                            height={500}
                            columns={exercises.columns}
                            paginationModel={{page: 0, pageSize: 25}}
                            pageSizeOptions={[10, 25, 50, 100]}
                            onAddClick={actions[0].onClick}
                            onEditClick={actions[1].onClick}
                            onDeleteClick={actions[2].onClick}
                        />
                    )}
                    {/* Create Dialog Instance */}
                    <ExerciseForm onSubmit={onCall("EXERCISE_CREATE")} label="Add Workout" dialogId="EXERCISE_CREATE" />
                    {/* Update Dialog Instance */}
                    {currentRow && <ExerciseForm onSubmit={onCall("EXERCISE_UPDATE")} label="Edit Workout" currentRow={currentRow} dialogId="EXERCISE_UPDATE" />}
                    {/* Delete Dialog Instance */}
                    {currentRow && <DeleteForm onDelete={onCall("EXERCISE_DELETE")} label="Delete Workout" currentRow={currentRow} dialogId="EXERCISE_DELETE" />}
                </Box>
            ),
        },
    ];

    return (
        <Box>
            {isMD && (
                <>
                    <SpeedDial ariaLabel="workout-controller" actions={actions} icon={<Difference />} />
                    <Tab onTabChange={onTabChange} tabIndex={tabIndex} data={tabs} sticky />
                </>
            )}
            {!isMD && <Paper><Tab onTabChange={onTabChange} tabIndex={tabIndex} data={tabs} /></Paper>}
        </Box>
    );
}

export default Workout;
