import {OptionsObject, SnackbarKey} from "notistack";
import {RegisterOptions} from "react-hook-form";

interface ErrorType{
    code:any
    message:string
}

export interface Payload{
    error?:ErrorType
    data?:any
}

// https://iamhosseindhv.com/notistack/api
export interface Notification {
    message:string
    key:SnackbarKey
    options:OptionsObject
    dismissed?:boolean
}

interface AutocompleteOptions {
    options:string[]
    multiple:boolean
}

interface TextAreaOptions {
    rows:number
    placeholder:string
}

export interface CheckListItem{
    key:string
    value:any
}

interface CheckListOptions {
    options:any[]
    itemKeys:CheckListItem[]
}

export interface FormSchema {
    id:string
    type:"text"|"password"|"submit"|"reset"|"autocomplete"|"datepicker"|"file"|"checklist"
    label:string
    registerOptions?:RegisterOptions
    joiOptions?:any
    autocompleteOptions?:AutocompleteOptions
    textAreaOptions?:TextAreaOptions
    checkListOptions?:CheckListOptions
}

export type DialogId="NONE" | "GRID_ROW" |
                "WORKOUT_CREATE" | "WORKOUT_UPDATE" | "WORKOUT_DELETE" |
                "SUPPLEMENT_CREATE" | "SUPPLEMENT_UPDATE" | "SUPPLEMENT_DELETE" |
                "MEAL_CREATE" | "MEAL_UPDATE" | "MEAL_DELETE" |
                "DIET_CREATE" | "DIET_UPDATE" | "DIET_DELETE" |
                "EXERCISE_CREATE" | "EXERCISE_UPDATE" | "EXERCISE_DELETE" |
                "LOG_CREATE" | "LOG_UPDATE" | "LOG_DELETE" | "LOG_COMMENT" | "LOG_RANGE"

export type ActionType="all"|"this-year"|"last-year"|"range";

export interface ConfState {
    notifications:Notification[]
    dialogId:DialogId
    currentRow:any
}

export enum ConfActionTypes{
    // -------------------------------------------LAYOUT
    CONF_SET_DIALOG_ID="@@CONF/SET_DIALOG_ID",
    CONF_SET_CURRENT_ROW="@@CONF/SET_CURRENT_ROW",

    // -------------------------------------------NOTIFIER
    CONF_NOTIFIER_ENQUEUE="@@CONF/NOTIFIER_ENQUEUE",
    CONF_NOTIFIER_CLOSE="@@CONF/NOTIFIER_CLOSE",
    CONF_NOTIFIER_REMOVE="@@CONF/NOTIFIER_REMOVE",

    // -------------------------------------------USER
    CONF_SIGNOUT="@@CONF/SIGNOUT",
}
