import React from "react";
import {Grid2 as Grid} from "@mui/material/";
import Joi from "joi";
import useForm, {IForm} from "../generics/useForm";
import {FormSchema, DialogId} from "../../store/types/confType";
import {SUPPLEMENT_TYPE_OPTIONS} from "../../config";
import Dialog from "../generics/Dialog";

export const FORM_SCHEMA:FormSchema[]=[
    {
        id: "name",
        type: "text",
        label: "Name",
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Set a Name",
            }),
    },
    {
        id: "company",
        type: "text",
        label: "Company",
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Set a Company",
            }),
    },
    {
        id: "type",
        type: "autocomplete",
        label: "Type",
        autocompleteOptions: {
            options: SUPPLEMENT_TYPE_OPTIONS,
            multiple: false,
        },
        joiOptions: Joi.string().valid(...SUPPLEMENT_TYPE_OPTIONS).required()
            .messages({
                "any.required": "Select",
                "any.only": "Select",
            }),
    },
];

interface SupplementProps{
    onSubmit:(data:any) => void
    currentRow?:any
    dialogId: DialogId
    label: string
}

/**
 * Supplement
 * @return {React.ReactElement}
 */
function Supplement(props:SupplementProps):React.ReactElement {
    const form:IForm=useForm(FORM_SCHEMA, props.currentRow);
    /**
     * constructLayout
     * @param {React.ReactElement[]} fields
     * @return {React.ReactElement}
     */
    const constructLayout=(fields:React.ReactElement[]):React.ReactElement => (

        <Dialog
            id={props.dialogId}
            label={props.label}
            onPrimaryAction={props.onSubmit}
            onPrimaryLabel={props.currentRow?"Edit":"Add"}
            form={form}
            content={(
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                        <Grid container rowSpacing={1} columnSpacing={{xs: 2, sm: 2, md: 2}}>
                            {fields.map((item:React.ReactElement) => <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}} key={item.key}>{item}</Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    );
    return constructLayout(form.fields);
}

export default Supplement;
