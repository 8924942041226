import React from "react";
import {Grid2 as Grid} from "@mui/material/";
import Joi, {CustomHelpers} from "joi";
import useForm, {IForm} from "../generics/useForm";
import {FormSchema, DialogId, CheckListItem} from "../../store/types/confType";
import {DIET_TYPE_OPTIONS, JOI_MAX_CHECK_LIST_VALUE} from "../../config";
import Dialog from "../generics/Dialog";

/**
 * validateCheckListItems
 * @param {any} value
 * @param {CustomHelpers<any>} helper
 * @return {CustomHelpers<any>}
 */
export const validateCheckListItems=(value:any, helper:CustomHelpers<any>):CustomHelpers<any> => {
    const errors=[];
    value.forEach((item:CheckListItem) => (
        Object.keys(item.value).forEach((key:string) => {
            const schema=JOI_MAX_CHECK_LIST_VALUE.validate({value: item.value[key]});
            if (schema.error) errors.push(schema);
        })
    ));
    return errors.length===0?value:helper.error("custom.invalid");
};

export const FORM_SCHEMA:FormSchema[]=[
    {
        id: "name",
        type: "text",
        label: "Name",
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Set a Name",
            }),
    },
    {
        id: "type",
        type: "autocomplete",
        label: "Type",
        autocompleteOptions: {
            options: DIET_TYPE_OPTIONS,
            multiple: false,
        },
        joiOptions: Joi.string().valid(...DIET_TYPE_OPTIONS).required()
            .messages({
                "any.required": "Select",
                "any.only": "Select",
            }),
    },
    {
        id: "meals",
        type: "checklist",
        label: "Meals",
        checkListOptions: {
            options: [],
            itemKeys: [{key: "qty", value: 1}],
        },
        joiOptions: Joi
            .array()
            .min(1)
            .required()
            .custom(validateCheckListItems)
            .messages({
                "any.required": "Required",
                "array.min": "Select at least 1 item",
                "custom.invalid": "Set a number. Min 0. Max 1000",
            }),
    },
    {
        id: "supplements",
        type: "autocomplete",
        label: "Supplements",
        autocompleteOptions: {
            options: [],
            multiple: true,
        },
        joiOptions: Joi.array().min(1).items(Joi.string()).required()
            .messages({
                "any.required": "Required",
                "array.min": "Select at least 1 item",
            }),
    },
];

interface DietProps{
    onSubmit:(data:any) => void
    currentRow?:any
    dialogId: DialogId
    label: string
    meals: any
    supplements: any
}

/**
 * Diet
 * @return {React.ReactElement}
 */
function Diet(props:DietProps):React.ReactElement {
    // pre-populate meals and supplements values in form
    FORM_SCHEMA.slice(2).forEach((item:any) => {
        /* eslint-disable no-param-reassign */
        const values=props[item.id as keyof DietProps];
        if (item.autocompleteOptions && values) item.autocompleteOptions.options=values.rows.map((row:any) => row.name);
        if (item.checkListOptions && values) item.checkListOptions.options=values.rows;
    });

    const form:IForm=useForm(FORM_SCHEMA, props.currentRow);
    /**
     * constructLayout
     * @param {React.ReactElement[]} fields
     * @return {React.ReactElement}
     */
    const constructLayout=(fields:React.ReactElement[]):React.ReactElement => (

        <Dialog
            id={props.dialogId}
            label={props.label}
            onPrimaryAction={props.onSubmit}
            onPrimaryLabel={props.currentRow?"Edit":"Add"}
            form={form}
            content={(
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                        <Grid container rowSpacing={1} columnSpacing={{xs: 2, sm: 2, md: 2}}>
                            {fields.map((item:React.ReactElement) => <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}} key={item.key}>{item}</Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    );
    return constructLayout(form.fields);
}

export default Diet;
