import React, {useState, Dispatch, SetStateAction, useEffect} from "react";
import {onAuthStateChanged, User, signOut, sendEmailVerification} from "firebase/auth";
import {useDispatch} from "react-redux";
import {auth as authBase} from "../../config";

export interface AuthProps{
    isLoading:boolean
    isAuthenticated:boolean
    user:User|null
}

/**
 * useAuth
 * @return {AuthProps}
 */
const useAuth=():AuthProps => {
    const dispatch=useDispatch();
    const [auth, setAuth]:[AuthProps, Dispatch<SetStateAction<AuthProps>>]=useState<AuthProps>({
        isLoading: true,
        isAuthenticated: false,
        user: null,
    });

    useEffect(() => {
        onAuthStateChanged(authBase, (user:any) => {
            if (user) setAuth({isLoading: false, isAuthenticated: true, user});
            else setAuth({isLoading: false, isAuthenticated: false, user: null});
        });

        if (auth.user?.emailVerified===false) {
            signOut(authBase)
                .then((res:any) => {
                    dispatch({
                        type: "@@CONF/NOTIFIER_ENQUEUE",
                        notification: {
                            message: "Verify Email!",
                            options: {variant: "error", action: "DISMISS", persist: true},
                        },
                    });
                    sendEmailVerification(auth.user as User);
                })
                .catch((error:any) => error);
        }
    }, [auth.user, auth.isAuthenticated, auth.isLoading, dispatch]);

    return auth;
};

export default useAuth;
