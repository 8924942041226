import React, {useState} from "react";
import {
    Grid2 as Grid,
    Paper as BasePaper,
    AppBar,
    Typography as BaseTypography,
    Button,
    Toolbar,
    IconButton,
    Link,
} from "@mui/material";
import {ThemeProvider, createTheme, Theme, styled} from "@mui/material/styles";
import {LightMode, DarkMode, Code, Terminal, FactCheck} from "@mui/icons-material";
import {deepPurple, grey} from "@mui/material/colors";
import Box from "../generics/Box";
import {APP_PATHS, SOCIAL_MEDIA} from "../../config";
import {TierCard} from "../../views/About";

const Typography = styled(BaseTypography)(() => ({
    fontFamily: "Poppins",
}));

const Paper = styled(BasePaper)(() => ({
    padding: "6px",
}));

const ICON_STYLE={fontSize: "80px"};

const ITEMS=[
    {icon: <Code sx={ICON_STYLE} />, label: "Build"},
    {icon: <FactCheck sx={ICON_STYLE} />, label: "Test"},
    {icon: <Terminal sx={ICON_STYLE} />, label: "Release"},
];

/**
 * HeroView
 * @return {React.ReactElement}
 */
function HeroView():React.ReactElement {
    const [mode, setMode]:any=useState("dark");

    const theme:Theme=createTheme({
        palette: {
            mode,
            primary: {
                main: deepPurple.A700,
            },
        },
    });

    /**
     * onMode
     * @param {React.MouseEvent} args
     */
    const onMode=(args:React.MouseEvent):void => {
        setMode(mode==="dark"?"light":"dark");
    };

    return (
        <Box sx={{flexGrow: 1, overflow: "auto", ...(mode==="dark" && {backgroundColor: grey[400]}), height: "100%"}}>
            <ThemeProvider theme={theme}>
                {/* AppBar */}
                <AppBar position="fixed">
                    <Toolbar variant="dense">
                        {/* logo */}
                        <Typography variant="h4" sx={{flexGrow: 1, fontWeight: "600"}}>zxc</Typography>
                        {/* about */}
                        <Button component="a" href={APP_PATHS.ABOUT} color="inherit" sx={{fontFamily: "Poppins", fontSize: "13pt", textTransform: "capitalize"}}>About</Button>
                        {/* mode */}
                        <IconButton color="inherit" size="large" onClick={onMode}>{mode==="dark"?<DarkMode />:<LightMode />}</IconButton>
                    </Toolbar>
                </AppBar>
                {/* main container */}
                <Box sx={{paddingLeft: "72px", paddingRight: "72px", marginTop: "68px"}}>
                    {/* Grid */}
                    <Grid container columnSpacing={8} rowSpacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                        {/* items */}
                        <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                            <Grid container spacing={8} justifyContent="center" alignItems="center">
                                {ITEMS.map((item) => (<Grid size={4} key={item.label}><TierCard {...item} /></Grid>))}
                            </Grid>
                        </Grid>
                        {/* item */}
                        <Grid size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                            <Paper elevation={4}><Typography variant="h6">Welcome to my App. My name is Haidar and I am a software Engineer.</Typography></Paper>
                        </Grid>
                    </Grid>
                    {/* social media */}
                    <Grid sx={{marginTop: "15px"}} spacing={2} container direction="row" justifyContent="center" alignItems="flex-start">
                        {SOCIAL_MEDIA.map((item:any) => <Grid key={item.url}><Link color="inherit" href={item.url}>{item.icon}</Link></Grid>)}
                    </Grid>
                </Box>
            </ThemeProvider>
        </Box>
    );
}

export default HeroView;
